import React from 'react';
import { Button as MButton } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../theme';

export const Cancel = styled(MButton)`
  border-radius: 4px;
  border: solid 1px ${() => theme.colors.grey300};
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  width: ${(props) => (props.width ? `${props.width}px` : undefined)};
  height: ${(props) => (props.height ? `${props.height}px` : undefined)};
  color: ${(props) => props.customizedcolor || theme.colors.body87};
`;

export const Button = styled(MButton)`
  background-color: ${(props) => props.customizedcolor || theme.colors.primary};
  border: solid 1px ${(props) => props.customizedcolor || theme.colors.primary};
  &:hover {
    background-color: ${(props) =>
      props.customizedcolor || theme.colors.primary};
    opacity: 0.9;
  }
  &:hover.Mui-disabled {
    background-color: ${(props) =>
      props.customizedcolor || theme.colors.primary};
    color: ${(props) =>
      props.disabled ? props.customizedfontcolor : theme.colors.primary};
    opacity: 0.9;
  }
  &.Mui-disabled {
    color: ${(props) =>
      props.disabled
        ? props.customizedfontcolor
        : theme.colors.customizedcolor};
  }
  border-radius: 8px;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  width: ${(props) => props.width || 'unset'};
  height: ${(props) => props.height || 'unset'};
  color: ${(props) => props.customizedfontcolor || theme.colors.white};
`;

export const ButtonInterval = styled.div`
  width: 16px;
`;

const RefreshIcon = styled.i`
  font-size: 1.375rem;
  color: ${(props) => props.theme.icon.default};
  line-height: initial;
`;

const RefreshContainer = styled.div`
  border: ${(props) => props.theme.boxBorder.active};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
`;

export const RefreshButton = ({ onClick }) => {
  return (
    <RefreshContainer>
      <RefreshIcon className="ri-refresh-line" onClick={onClick}></RefreshIcon>
    </RefreshContainer>
  );
};
